@media (min-width:1920px) and (max-width:2560px) {
  .sub-header {
    padding: 180px 0;
  }

  .shuttle-service-image {
    width: 40%;
  }

  .page-not-found {
    height: 700px;
  }

  .page-not-found .col-lg-12 {
    bottom: 120px;
  }

  .thankyou-area .thanks-image {
    height: 700px;
  }

  .uspbenefit-image {
    width: 40%;
  }

  .uspbenefit-reverse .uspbenefit-box {
    padding: 80px 60px 80px 150px;
  }

  .price-contact .price-contact-vec1 {
    bottom: -60px;
  }

  .price-contact {
    padding-bottom: 120px;
  }

  .price-head-vec2 {
    width: 190px;
  }

  .price-head-vec1 {
    width: 190px;
    bottom: -70px;
  }

  .price-service-vec1 {
    width: 180px;
  }

  .price-manager-vec2 {
    width: 220px;
  }

  .price-manager-vec1 {
    width: 170px;
  }

  .web-business-vec1 {
    width: 820px;
  }

  .web-business-vec2 {
    width: 230px
  }

  .web-business-vec3 {
    bottom: -210px;
    width: 100px
  }

  .webapp-world-map {
    width: 33%
  }

  .caselist-top-cards .top-cards-vec2 {
    height: 700px
  }

  .industry-highend-two {
    margin: 210px 0 240px
  }

  .industry-highend-vec2 {
    width: 150px;
    bottom: -240px
  }

  .health-industry-box div div {
    transform: translateY(90px)
  }

  .health-industry-back img {
    width: 100%
  }

  .health-industry-box div p {
    min-height: 37px
  }

  .ecom-collaborate-vec2 {
    width: 1230px
  }

  .ecom-collaborate-vec1 {
    width: 480px
  }

  .industry-menu-scroll {
    height: auto
  }

  .bespoke-education .bespoke-vec-3{
    width: 230px;
  }
  .bespoke-education .bespoke-vec-2{
    width: 160px;
  }
}

@media (min-width:1801px) and (max-width:1919px) {
  .sub-header {
    padding: 170px 0
  }

  .shuttle-service-image {
    width: 40%
  }

  .page-not-found {
    height: 650px
  }

  .page-not-found .col-lg-12 {
    bottom: 120px
  }

  .thankyou-area .thanks-image {
    height: 650px
  }

  .uspbenefit-image {
    width: 40%
  }

  .uspbenefit-reverse .uspbenefit-box {
    padding: 80px 60px 80px 150px
  }

  .price-contact .price-contact-vec1 {
    bottom: -30px
  }

  .price-contact {
    padding-bottom: 120px
  }

  .price-head-vec2 {
    width: 170px
  }

  .price-head-vec1 {
    width: 170px;
    bottom: -60px
  }

  .price-service-vec1 {
    width: 180px
  }

  .price-manager-vec2 {
    width: 220px
  }

  .price-manager-vec1 {
    width: 170px
  }

  .web-business-vec1 {
    width: 820px
  }

  .web-business-vec2 {
    width: 230px
  }

  .web-business-vec3 {
    bottom: -210px;
    width: 100px
  }

  .webapp-world-map {
    width: 33%
  }

  .caselist-top-cards .top-cards-vec2 {
    height: 700px
  }

  .industry-highend-two {
    margin: 190px 0 220px
  }

  .industry-highend-vec2 {
    width: 140px;
    bottom: -220px
  }

  .health-industry-box div div {
    transform: translateY(90px)
  }

  .health-industry-back img {
    width: 100%
  }

  .health-industry-box div p {
    min-height: 37px
  }

  .ecom-collaborate-vec2 {
    width: 1150px
  }

  .ecom-collaborate-vec1 {
    width: 480px
  }

  .industry-menu-scroll {
    height: auto
  }

  .bespoke-education .bespoke-vec-3{
    width: 220px;
  }
  .bespoke-education .bespoke-vec-2{
    width: 150px;
  }
}

@media (min-width:1681px) and (max-width:1800px) {
  .sub-header {
    padding: 150px 0
  }

  .shuttle-service-image {
    width: 42%
  }

  .page-not-found {
    height: 600px
  }

  .page-not-found .col-lg-12 {
    bottom: 120px
  }

  .thankyou-area .thanks-image {
    height: 600px
  }

  .uspbenefit-image {
    width: 40%
  }

  .uspbenefit-reverse .uspbenefit-box {
    padding: 80px 60px 80px 150px
  }

  .price-contact .price-contact-vec1 {
    bottom: -10px
  }

  .price-contact {
    padding-bottom: 120px
  }

  .price-head-vec2 {
    width: 170px
  }

  .price-head-vec1 {
    width: 170px;
    bottom: -60px
  }

  .price-service-vec1 {
    width: 160px
  }

  .price-manager-vec2 {
    width: 200px
  }

  .price-manager-vec1 {
    width: 150px
  }

  .web-business-vec1 {
    width: 820px
  }

  .web-business-vec2 {
    width: 230px
  }

  .web-business-vec3 {
    bottom: -210px;
    width: 100px
  }

  .webapp-world-map {
    width: 35%
  }

  .caselist-top-cards .top-cards-vec2 {
    height: 700px
  }

  .industry-highend-two {
    margin: 170px 0 200px
  }

  .industry-highend-vec2 {
    width: 130px;
    bottom: -200px
  }

  .health-industry-box div div {
    transform: translateY(90px)
  }

  .health-industry-back img {
    width: 100%
  }

  .health-industry-box div p {
    min-height: 37px
  }

  .ecom-collaborate-vec2 {
    width: 1000px
  }

  .ecom-collaborate-vec1 {
    width: 480px
  }

  .industry-menu-scroll {
    height: auto
  }

  .bespoke-education .bespoke-vec-3{
    width: 210px;
  }
  .bespoke-education .bespoke-vec-2{
    width: 150px;
  }
}

@media (min-width:1600px) and (max-width:1680px) {
  .sub-header {
    padding: 130px 0
  }

  .shuttle-service-image {
    width: 40%
  }

  .page-not-found {
    height: 600px
  }

  .page-not-found .col-lg-12 {
    bottom: 120px
  }

  .thankyou-area .thanks-image {
    height: 600px
  }

  .uspbenefit-image {
    width: 40%
  }

  .uspbenefit-reverse .uspbenefit-box {
    padding: 80px 60px 80px 150px
  }

  .price-contact .price-contact-vec1 {
    bottom: -20px
  }

  .price-contact {
    padding-bottom: 120px
  }

  .price-head-vec1 {
    width: 170px;
    bottom: -60px
  }

  .price-service-vec1 {
    width: 160px
  }

  .price-manager-vec2 {
    width: 200px
  }

  .price-manager-vec1 {
    width: 150px
  }

  .web-business-vec1 {
    width: 820px
  }

  .web-business-vec2 {
    width: 230px
  }

  .web-business-vec3 {
    bottom: -210px;
    width: 100px
  }

  .webapp-world-map {
    width: 37%
  }

  .caselist-top-cards .top-cards-vec2 {
    height: 700px
  }

  .industry-highend-two {
    margin: 150px 0 190px
  }

  .industry-highend-vec2 {
    width: 120px;
    bottom: -190px
  }

  .health-industry-box div div {
    transform: translateY(90px)
  }

  .health-industry-back img {
    width: 100%
  }

  .health-industry-box div p {
    min-height: 47px
  }

  .ecom-collaborate-vec2 {
    width: 900px
  }

  .ecom-collaborate-vec1 {
    width: 480px
  }

  .industry-menu-scroll {
    height: auto
  }

  .bespoke-education .bespoke-vec-3{
    width: 190px;
  }
  .bespoke-education .bespoke-vec-2{
    width: 140px;
  }
}

@media (min-width:1441px) and (max-width:1599px) {
  .sub-header {
    padding: 110px 0
  }

  .shuttle-service-image {
    width: 40%
  }

  .page-not-found {
    height: 520px
  }

  .page-not-found .col-lg-12 {
    bottom: 80px
  }

  .thankyou-area .thanks-image {
    height: 520px
  }

  .uspbenefit-image {
    width: 40%
  }

  .uspbenefit-reverse .uspbenefit-box {
    padding: 80px 60px 80px 150px
  }

  .price-contact .price-contact-vec1 {
    bottom: -20px
  }

  .price-contact {
    padding-bottom: 120px
  }

  .price-head-vec1 {
    width: 160px
  }

  .price-service-vec1 {
    width: 160px
  }

  .price-manager-vec2 {
    width: 200px
  }

  .price-manager-vec1 {
    width: 150px
  }

  .web-business-vec1 {
    width: 820px
  }

  .web-business-vec2 {
    width: 230px
  }

  .web-business-vec3 {
    bottom: -210px;
    width: 100px
  }

  .webapp-story-vec {
    bottom: -80px;
    width: 110px
  }

  .caselist-top-cards .top-cards-vec2 {
    height: 700px
  }

  .industry-highend-two {
    margin: 140px 0 170px
  }

  .industry-highend-vec2 {
    width: 120px;
    bottom: -170px
  }

  .ecom-collaborate-vec2 {
    width: 870px
  }

  .ecom-collaborate-vec1 {
    width: 480px
  }

  .health-industry-box div p {
    min-height: 47px
  }

  .industry-menu-scroll {
    height: auto
  }

  .bespoke-education .bespoke-vec-3{
    width: 190px;
  }
  .bespoke-education .bespoke-vec-2{
    width: 140px;
  }
}

@media (min-width:1400px) and (max-width:1440px) {
  .sub-header {
    padding: 100px 0
  }

  .shuttle-service-image {
    width: 40%
  }

  .page-not-found {
    height: 520px
  }

  .page-not-found .col-lg-12 {
    bottom: 80px
  }

  .thankyou-area .thanks-image {
    height: 520px
  }

  .uspbenefit-image {
    width: 40%
  }

  .uspbenefit-reverse .uspbenefit-box {
    padding: 80px 60px 80px 150px
  }

  .caselist-top-cards .top-cards-vec2 {
    height: 700px
  }

  .industry-highend-two {
    margin: 120px 0 150px
  }

  .industry-highend-vec2 {
    width: 120px;
    bottom: -150px
  }

  .health-industry-box div p {
    min-height: 47px
  }
}

@media (min-width:1300px) and (max-width:1366px) {
  .uspbenefit-image {
    width: 40%
  }

  .uspbenefit-reverse .uspbenefit-box {
    padding: 80px 60px 80px 150px
  }

  .industry-highend-two {
    margin: 100px 0 140px
  }

  .industry-highend-vec2 {
    width: 110px;
    bottom: -140px
  }

  .health-industry-box div p {
    min-height: 55px
  }
}

@media (min-width:992px) and (max-width:1200px) {
  .blog-card-content h4 {
    font-size: 20px
  }

  .blog-card-content .card-link-more {
    font-size: 12px
  }

  .blog-card-content .card-link-more img {
    width: 10px
  }

  .blog-mainsec-img img {
    height: 500px
  }

  .contact-box-wrap {
    padding: 40px 15px;
    height: 275px
  }

  .contact-box-content img {
    width: 40px
  }

  .contact-box-content h3 {
    font-size: 25px
  }

  .shuttle-tech-box div h4 {
    font-size: 24px
  }

  .tab-content-box {
    min-height: 128px
  }

  .privacy-tab-wrap .nav-pills .nav-link {
    font-size: 12px
  }

  .case-detailcard-content .heading {
    font-size: 20px
  }

  .sub-header-content p {
    font-size: 16px;
    display: block;
    text-align: center
  }

  .sub-header-content p span {
    font-size: 18px;
    vertical-align: middle
  }

  .shuttsolution-box ul {
    min-height: 100px
  }

  .shuttsolution-box h4,
  .shuttsolution-box h3 {
    min-height: 85px
  }

  .shutbook-vector-2 {
    width: 330px
  }

  .shutbook-vector-3 {
    width: 220px;
    left: -110px
  }

  .shuttlehead-text .img-responsive {
    width: 470px
  }

  .footer-policy-box a {
    font-size: 10px
  }

  .pricing-launch-box h4,
  .pricing-launch-box h3 {
    font-size: 24px
  }

  .pricing-launch-box p {
    min-height: 105px
  }

  .br-img-animation img {
    width: 220px
  }

  .br-mobility-link .nav-item {
    margin-bottom: 25px
  }

  .bike-leaders-img img {
    height: 100%;
    width: 100%;
    object-fit: cover
  }

  .bike-leaders-text h5,
  .bike-leaders-text h3 {
    font-size: 18px
  }

  .bike-leaders-text {
    padding: 20px 30px
  }

  .web-booking-box {
    margin: 30px 0 0 0;
    padding: 30px 20px
  }

  .bike-Rental-head {
    padding: 80px 0
  }

  .br-workflow-box .heading {
    font-size: 20px
  }

  .custom-app-head {
    width: 70%
  }

  .custom-app-head .heading br {
    display: none
  }

  .enterprises-box .heading {
    min-height: 53px
  }

  .custom-cycle {
    padding-top: 110px !important;
    padding-bottom: 65px !important
  }

  .webapp-service-box {
    min-height: 355px
  }

  .custom-cycle-details .heading br {
    display: none
  }

  .webapp-story-details .heading br {
    display: none
  }

  .industries-banner .custom-app-head .heading span {
    font-size: 35px
  }

  .industries-banner .custom-app-head .heading br {
    display: block
  }

  .industry-right-box img {
    height: 413px
  }

  .industry-searvice-box {
    padding: 20px 15px
  }

  .industry-design-tab li button {
    margin: 0 10px
  }

  .industry-desing .industry-design-vec {
    width: 70px
  }

  .industry-highend-vec1 {
    width: 75px
  }

  .industry-highend-two {
    margin: 40px 0 80px
  }

  .industry-highend-vec2 {
    width: 70px;
    bottom: -80px
  }

  .industry-highend-img .highend-vec {
    top: -90px;
    left: -100px
  }

  .industry-highend-two {
    margin: 90px 0 120px
  }

  .industry-highend-vec2 {
    width: 90px;
    bottom: -120px
  }

  .industry-need-tab li button {
    padding: 20px 10px !important;
    font-size: 14px
  }

  .industry-need-tab li button::before {
    right: -13px
  }

  .patient-support-box h4 {
    font-size: 20px;
    min-height: 48px
  }

  .patient-support-box p {
    min-height: 84px
  }

  .health-industry-box div div {
    padding: 0;
    padding-left: 25px
  }

  .health-industry-head {
    left: 90px
  }

  .health-industry-box div p {
    min-height: 70px
  }

  .health-workflow-content h3 br {
    display: none
  }

  .health-expertise-img {
    width: 330px
  }

  .health-expertise-areas .nav-pills .nav-link {
    font-size: 18px
  }

  .healthcare-banner .custom-app-head .heading br {
    display: block
  }

  .food-vendor-cards .nav-pills .nav-link {
    margin: 10px 0;
    margin-left: 20px
  }

  .food-vendor-card {
    padding: 20px 20px 20px 30px
  }

  .food-admin-head div {
    top: 70px
  }

  .food-admin-box {
    padding: 0 15px
  }

  .food-admin-box::before {
    right: -15px
  }

  .food-addon-wrapper {
    padding: 20px
  }

  .food-addon-wrapper h4 {
    font-size: 20px
  }

  .food-smbs-box span {
    font-size: 40px
  }

  .food-smbs-box p br {
    display: none
  }

  .pharm-banner-details h1 {
    font-size: 40px
  }

  .pharm-banner-details h1 small {
    font-size: 36px
  }

  .pharm-order-vec {
    width: 360px
  }

  .brand-farm-box h3 {
    font-size: 18px
  }

  .pharmacy-step-detail h4 {
    font-size: 34px
  }

  .pharmacy-step-detail span {
    font-size: 28px
  }

  .ecom-banner .custom-app-head {
    width: 80%
  }

  .vertical-accordion {
    height: 390px
  }

  .industry-menu-scroll {
    height: 400px
  }

  .edu-counter-content div span span{
    font-size: 50px;
  }
}

@media (min-width:1200px) and (max-width:1200px) {
  .industry-right-box img {
    height: auto
  }

  .industry-searvice-box {
    padding: 30px
  }

  .industry-design-tab li button {
    margin: 0 33px
  }

  .patient-support-box h4 {
    font-size: 24px;
    min-height: 57px
  }

  .health-industry-head {
    left: 140px
  }

  .health-industry-box div div {
    padding: 20px
  }

  .health-industry-box div p {
    min-height: 0
  }

  .health-expertise-img {
    width: 350px
  }

  .food-vendor-card {
    padding: 25px 25px 25px 40px
  }

  .food-vendor-cards .nav-pills .nav-link {
    margin: 18px 0;
    margin-left: 20px
  }

  .food-addon-wrapper h4 {
    font-size: 22px
  }

  .food-addon-wrapper {
    padding: 30px
  }

  .food-smbs-box p br {
    display: block
  }

  .ecom-banner .custom-app-head {
    width: 60%
  }

  .vertical-accordion {
    height: 450px
  }
}

@media (min-width:768px) and (max-width:991px) {
  .blog-main-content h1 {
    font-size: 25px
  }

  .blog-mainsec-img img {
    height: 500px
  }

  .sub-header-content h1 {
    font-size: 42px
  }

  .sub-header-content p {
    font-size: 18px
  }

  .sub-header-content p span {
    font-size: 24px;
    vertical-align: middle
  }

  .contact-box-content img {
    width: 30px
  }

  .contact-box-content h3 {
    font-size: 22px
  }

  .contact-box-wrap {
    padding: 40px 15px;
    height: 275px
  }

  .in-touch-wrap {
    height: 100%
  }

  .cont-nunbox {
    height: 100%
  }

  .contact-image {
    height: 100%;
    object-fit: cover
  }

  .cont-number div {
    padding: 0 10px
  }

  .cont-number div h4,
  .cont-number div h3 {
    font-size: 32px
  }

  .in-touch-form {
    padding: 20px
  }

  .shuttle-need-footer h4 br,
  .shuttle-need-footer h2 br {
    display: none
  }

  .shuttle-need-formhead,
  .start-conversation-heading {
    margin-top: 30px
  }

  .user-shuttle-need {
    margin: 30px 0
  }

  .car-rental-subhead {
    padding: 30px 0
  }

  .subhead-main-image {
    margin-top: 20px
  }

  .earning-box h4,
  .earning-box h3 {
    font-size: 24px
  }

  .car-mobility {
    margin: 30px 0
  }

  .success-key-image {
    margin-bottom: 30px
  }

  .page-not-found {
    height: 350px
  }

  .page-not-found p {
    font-size: 20px
  }

  .page-not-found a {
    margin-top: 0
  }

  .page-not-found .col-lg-12 {
    bottom: 30px
  }

  .thankyou-area .thanks-image {
    height: 350px
  }

  .thankyou-content-box p {
    font-size: 18px
  }

  .thankyou-message {
    width: 50px;
    height: 50px;
    margin-bottom: 5px
  }

  .user-info-popup .modal-body {
    padding: 10px
  }

  .user-info-modalbox .heading {
    font-size: 28px
  }

  .modal-userinfo-image {
    border-radius: 8px
  }

  .privacy-tab-wrap .nav-pills .nav-link {
    font-size: 12px
  }

  .privacy-heading {
    font-size: 22px
  }

  .tab-content-box h4,
  .tab-content-box h3 {
    font-size: 18px
  }

  .interface-tabs .tab-content .tab-anim-img {
    width: 290px
  }

  .taxitech-box div h4,
  .taxitech-box div h3 {
    font-size: 24px
  }

  .taxitech-box div {
    top: 100%
  }

  .uspbenefit-box {
    padding: 50px 110px 50px 20px
  }

  .uspbenefit-reverse .uspbenefit-box {
    padding: 50px 20px 50px 110px
  }

  .uspbenefit-box img {
    width: 50px
  }

  .tab-content-box {
    min-height: 165px
  }

  .taxitab-wave {
    width: 170px;
    height: 170px
  }

  .taxitab-wave:before {
    width: 130px;
    height: 130px
  }

  .taxitab-wave:after {
    width: 160px;
    height: 160px
  }

  .interface-tab-body .row {
    align-items: center
  }

  .main-head-case {
    font-size: 65px;
    margin-bottom: 50px
  }

  .studybox-detail h4 {
    font-size: 22px
  }

  .studybox-detail ul li {
    padding: 5px 12px
  }

  .circle-waves {
    bottom: -250px;
    width: 480px;
    height: 480px
  }

  .shuttlehead-text .img-responsive {
    width: 340px
  }

  .netzero-vector {
    width: 200px;
    top: -100px
  }

  .shuttexplore-vector-1 {
    width: 320px !important
  }

  .shutbook-vector-1 {
    width: 260px;
    left: -80px
  }

  .shutbook-vector-2 {
    width: 330px
  }

  .shutbook-vector-3 {
    left: -140px;
    width: 260px
  }

  .netzero-box h4 {
    font-size: 22px
  }

  .shuttservice-detail h4 {
    font-size: 24px
  }

  .suttbook-fixed img {
    width: 88%
  }

  .shuttle-service-box {
    padding: 20px 120px 20px 20px
  }

  .shuttle-service-reverse .shuttle-service-box {
    padding: 20px 20px 20px 120px
  }

  .price-head-content label {
    font-size: 28px
  }

  .pricing-launch-box h4,
  .pricing-launch-box h3 {
    font-size: 22px
  }

  .price-business-box {
    margin-bottom: 30px
  }

  .price-searvices {
    margin: 30px 0 80px
  }

  .price-manager-box .heading {
    font-size: 24px
  }

  .price-partners span {
    font-size: 28px
  }

  .price-contact .heading {
    font-size: 50px
  }

  .price-manager-vec1 {
    width: 90px
  }

  .price-manager-vec2 {
    width: 120px;
    bottom: -120px
  }

  .price-head-vec2 {
    width: 100px
  }

  .price-head-vec1 {
    width: 120px;
    bottom: -30px
  }

  .price-head-img img {
    width: 90%
  }

  .price-service-vec1 {
    width: 80px
  }

  .footer-policy-box a {
    margin-top: 10px
  }

  .footer-social {
    justify-content: right
  }

  .certificate-info-popup .modal-dialog {
    max-width: 720px !important
  }

  .modal-certificate-content .heading {
    font-size: 22px
  }

  .certificate-info-popup .modal-body {
    padding: 25px
  }

  .price-searvice-box h4,
  .price-searvice-box h3 {
    font-size: 20px
  }

  .footer-gradiant-links a {
    word-wrap: break-word
  }

  .bike-Rental-head {
    padding: 80px 0
  }

  .br-img-animation img {
    margin: 30px auto;
    width: 220px
  }

  .br-animate-box {
    width: 300px;
    height: 300px
  }

  .brmobility-data {
    padding: 25px;
    margin-right: 0;
    padding-bottom: 0
  }

  .br-mobility-link .nav-link div img {
    width: 25px
  }

  .br-mobility-link {
    margin-top: 0;
    margin-bottom: 30px !important
  }

  .br-mobility-link .nav-item {
    margin-bottom: 30px
  }

  .bike-leaders-box {
    flex-direction: column-reverse
  }

  .bike-leaders-text,
  .bike-leaders-img {
    width: 100%
  }

  .bike-leaders-img img {
    width: 100%
  }

  .web-booking-box {
    margin: 30px 0 0 0
  }

  .custom-app-banner {
    margin-bottom: 40px
  }

  .custom-app-head {
    width: 80%
  }

  .custom-app-head .sub-heading br,
  .custom-app-head .heading br {
    display: none
  }

  .custom-head-logobox {
    padding: 15px 30px
  }

  .custom-head-logobox img {
    width: 52px
  }

  .custom-app-banner .container {
    bottom: -40px
  }

  .product-need-head {
    font-size: 24px
  }

  .custom-cycle {
    padding: 0 !important
  }

  .custom-cycle-details {
    margin-top: 80px;
    padding: 0
  }

  .enterprises-box .heading {
    min-height: 52px
  }

  .custom-cycle-card {
    position: static;
    margin-top: 20px;
    height: auto
  }

  .custom-cycle-card h4 br {
    display: none
  }

  .why-reasons-wrap {
    padding: 0
  }

  .why-custom-vec {
    width: 60px
  }

  .custom-tool-vec {
    width: 100px
  }

  .tech-giant-years span {
    font-size: 40px
  }

  .tech-giant-years {
    margin-top: 20px
  }

  .web-business-vec1 {
    width: 230px
  }

  .web-business-vec2 {
    width: 90px
  }

  .web-business-vec3 {
    bottom: -45px;
    width: 70px
  }

  .web-app-world {
    display: block
  }

  .webapp-world-map {
    position: unset;
    width: 100%;
    display: block
  }

  .webapp-world-container img {
    width: 100%
  }

  .map-dot-container img {
    width: 25px
  }

  .webapp-story-vec {
    bottom: -40px;
    width: 60px
  }

  .custom-cycle.web-app-cycle {
    margin: 0 0 50px
  }

  .webapp-impression-box {
    width: 100%;
    position: relative;
    padding: 40px;
    top: 0;
    margin-bottom: 30px
  }

  .webapp-impression-box:last-child {
    margin-bottom: 0
  }

  .webapp-impression-details {
    margin-top: 20px
  }

  .custom-cycle-details .heading br {
    display: none
  }

  .web-app-impression {
    padding-bottom: 0px !important;
    margin-top: 80px
  }

  .webapp-service-box {
    min-height: auto
  }

  .webapp-impression-image img {
    width: 75%;
    margin: 0 auto
  }

  .webapp-story-image img {
    width: 75%;
    margin: 0 auto;
    margin-bottom: 20px
  }

  .topcard-img-wrap span {
    font-size: 12px
  }

  .topcard-head img {
    width: 90px
  }

  .case-text-details {
    margin-top: 30px
  }

  .industries-banner .custom-app-head .heading span {
    font-size: 30px
  }

  .industries-banner .custom-app-head .heading br {
    display: block
  }

  .industry-desing .industry-design-vec {
    width: 70px
  }

  .design-tab-wrap .industry-truck-img {
    width: 70px
  }

  .industry-design-tab li button {
    margin-bottom: 10px !important
  }

  .industry-design-tab li button::before {
    bottom: -5px
  }

  .industry-highend-img .highend-vec {
    top: -60px;
    left: -80px
  }

  .track-details-wrap {
    flex-direction: column
  }

  .track-details-box {
    width: 100%
  }

  .industry-highend-vec1 {
    width: 70px;
    transform: translateY(-50%)
  }

  .industry-highend-two {
    margin: 40px 0
  }

  .industry-highend-vec2 {
    width: 70px;
    bottom: -80px
  }

  .industry-transform .heading br {
    display: none
  }

  .industry-transform-box img {
    width: 65%
  }

  .industry-highend-two .industry-highend-img {
    width: 40%
  }

  .industry-need-area .heading br {
    display: none
  }

  .industry-need-tab {
    justify-content: space-evenly
  }

  .industry-need-tab li button::before {
    opacity: 0
  }

  .industry-needtab-content img {
    width: 60%
  }

  .industry-needtab-content div {
    width: 40%
  }

  .healthcare-banner .custom-app-head .heading br {
    display: block
  }

  .healthcare-banner .heading span {
    font-size: 28px
  }

  .healthcare-banner .sub-heading {
    font-size: 13px;
    margin: 5px 0 10px
  }

  .health-expertise-areas .nav-pills .nav-link {
    font-size: 14px
  }

  .health-expertise-content ul li {
    font-size: 12px
  }

  .health-expertise-content p {
    font-size: 12px
  }

  .health-expertise-header .sub-heading br {
    display: none
  }

  .health-expertise-img {
    width: 250px
  }

  .health-expertise-content .heading {
    font-size: 24px
  }

  .health-challenges .heading br {
    display: none
  }

  .patient-support-detail div {
    padding: 0 30px
  }

  .health-industry-head {
    top: 20px;
    left: 30px
  }

  .health-industry-box div div {
    padding-left: 40px
  }

  .health-industry-box div p {
    min-height: auto
  }

  .health-tech-big-box div img {
    width: 35px
  }

  .health-tech-big-box div span {
    font-size: 40px
  }

  .health-tech-big-box div h4 {
    font-size: 24px
  }

  .health-workflow-content h3 {
    font-size: 24px
  }

  .health-workflow-content h3 br {
    display: none
  }

  .health-workflow-content-2 p {
    margin: 5px 0 10px
  }

  .health-workflow-content-2 .workflow-list {
    align-items: start
  }

  .health-workflow-content-2 .workflow-list ul li {
    margin-bottom: 5px
  }

  .orderapp-tabs-wrap div h3 {
    font-size: 18px
  }

  .orderapp-tabs-wrap p {
    font-size: 12px
  }

  .orderapp-img-container .orange-blush {
    width: 200px;
    left: 48%
  }

  .orderapp-img-container .aqua-blush {
    left: -20px
  }

  .food-vendor-card h4 {
    font-size: 18px
  }

  .food-vendor-card p {
    font-size: 12px
  }

  .food-vendor-card {
    padding: 15px 15px 15px 25px
  }

  .food-vendor-cards .nav-pills .nav-link {
    margin: 8px 0 8px 20px
  }

  .food-vendor-cards .nav-pills .nav-link.active,
  .food-vendor-cards .nav-pills .show>.nav-link {
    transform: translateX(10px)
  }

  .fooddelivery-tabs-wrap div h3 {
    font-size: 18px
  }

  .fooddelivery-tabs-wrap p {
    margin-top: 5px;
    font-size: 12px
  }

  .food-delivery-head h3 {
    font-size: 32px
  }

  .fooddelivery-img-container .orange-blush {
    width: 200px;
    left: 48%
  }

  .fooddelivery-img-container .purple-blush {
    left: -30px
  }

  .food-admin-head div .heading {
    font-size: 32px
  }

  .food-admin-head div {
    top: 70px
  }

  .food-admin-box {
    padding: 0
  }

  .food-admin-box h4 {
    font-size: 18px
  }

  .food-admin-box p {
    font-size: 12px
  }

  .food-admin-box::before {
    right: -15px
  }

  .food-admin-box img {
    width: 35px
  }

  .food-smbs-box img {
    width: 43px
  }

  .food-smbs-box {
    padding: 15px
  }

  .food-smbs-box span {
    font-size: 28px
  }

  .food-smbs-box p {
    font-size: 12px
  }

  .food-smbs-box p br {
    display: none
  }

  .pharm-banner-details h1 small {
    font-size: 24px
  }

  .pharm-banner-details .purple-gradiant {
    font-size: 12px
  }

  .pharm-banner-details h1 {
    font-size: 28px;
    margin-bottom: 5px
  }

  .pharm-banner-details p {
    margin-bottom: 15px
  }

  .pharm-order-vec {
    width: 300px
  }

  .pharm-brand-image .brand-vec-top {
    width: 55px;
    left: -15px
  }

  .pharmacy-step-detail span {
    font-size: 26px
  }

  .pharmacy-step-detail h4 {
    font-size: 32px
  }

  .pharm-growth-details span {
    font-size: 12px
  }

  .pharm-growth-details h4 {
    font-size: 22px
  }

  .pharm-growth-details ul li {
    margin-bottom: 5px
  }

  .ecom-banner .custom-app-head {
    width: 80%
  }

  .ecom-banner.custom-app-banner {
    margin-bottom: 0
  }

  .ecom-banner .heading {
    font-size: 32px
  }

  .ecom-banner .heading span {
    font-size: 30px
  }

  .ecom-retail-area .heading {
    font-size: 32px
  }

  .vertical-accordion {
    height: 300px
  }

  .vertical-accordion__item:not(.active__item) {
    flex: 0 0 55px
  }

  .vertical-accordion__header {
    font-size: 15px;
    width: 55px
  }

  .vertical-accordion__title {
    font-size: 18px
  }

  .vertical-accordion__text {
    font-size: 12px
  }

  .vertical-accordion__background:after {
    background: rgb(0 0 0 / .6)
  }

  .ecom-system-upper h3 {
    font-size: 32px
  }

  .ecom-erp-wrap h4 {
    font-size: 18px
  }

  .ecom-erp-wrap p {
    min-height: 41px
  }

  .ecom-room-wrapper div span {
    font-size: 16px;
    margin-left: 10px
  }

  .ecom-room-wrapper div img {
    width: 40px
  }

  .ecom-consult-box div h4 {
    font-size: 18px
  }

  .ecom-consult-box span {
    margin: 0 15px 0 0;
    font-size: 14px
  }

  .ecom-textslide-details img {
    width: 50px
  }

  .ecom-textslide-details h4 {
    font-size: 18px
  }

  .ecomcase-text-slides .ecom-textslide-details {
    margin-top: 10px
  }

  .ecomcase-text-slides .slick-next,
  .ecomcase-text-slides .slick-prev {
    bottom: -10px;
    width: 35px;
    height: 35px
  }

  .industry-menu-scroll {
    height: 350px
  }

  .industries-big-menu {
    width: 17%
  }

  .industries-big-menu h4 {
    font-size: 18px
  }

  .industries-big-menu .solution-menubox a {
    font-size: 12px !important;
    letter-spacing: .2px
  }

  .comingsoon-detail {
    width: 90%;
    top: 62%
  }

  .comingsoon-detail h1 {
    font-size: 22px
  }

  .comingsoon-detail .default-white {
    margin-top: 15px
  }
  /*-----education app page-----*/
  .educ-service-tabhead::before{
    right: 0;
  }
  .elearning-boxes div span{
    font-size: 14px;
  }
  .elearning-boxes img{
    width: 80px;
  }
  .great-edu-box span{
    font-size: 20px;
  }
  .great-edu-box div h4{
    font-size: 18px;
  }
  .edu-counter-content{
    margin: 15px 0;
  }
}

@media (min-width:600px) and (max-width:767px) {
  .blog-main-area {
    padding-top: 0;
    margin-bottom: 50px;
    padding-right: 0
  }

  .blog-top-content {
    padding: 15px 0
  }

  .blog-main-content h1 {
    font-size: 22px
  }

  .blog-main-wrapper .col-lg-6.left-pad-fix {
    padding-left: 15px
  }

  .blog-main-wrapper .col-lg-6.right-pad-fix {
    padding-right: 15px
  }

  .blog-mainsec-img img {
    border-radius: 15px;
    height: auto;
    margin-bottom: 15px
  }

  .our-blogs-area {
    margin-bottom: 50px
  }

  .blog-card-content h4 {
    font-size: 20px
  }

  .sub-header-content h1 {
    font-size: 26px
  }

  .sub-header-content p {
    font-size: 15px;
    display: block;
    text-align: center
  }

  .sub-header-content p span {
    font-size: 15px;
    vertical-align: middle
  }

  .contact-info-area {
    margin-bottom: 50px
  }

  .contact-box-wrap {
    height: auto
  }

  .get-touch-img {
    margin: 0 auto;
    margin-bottom: 30px
  }

  .contact-image {
    border-radius: 5px 5px 0 0
  }

  .name-email-wrap {
    flex-direction: column
  }

  .name-email-wrap input {
    width: 100%
  }

  .get-intouch-place {
    margin: 50px 0
  }

  .header-detail-place h1 {
    font-size: 25px
  }

  .blog-list-content h2,
  .blog-list-content h3,
  .blog-list-content h4 {
    font-size: 22px
  }

  .blog-detail-area {
    margin-bottom: 50px
  }

  .blogdetail-cards {
    margin-bottom: 20px
  }

  .shuttle-teamlogo {
    margin: 50px 0 30px
  }

  .shuttle-tech-area .heading.center-heading br {
    display: none
  }

  .shuttle-teamlogo-box div img {
    width: 85px
  }

  .shuttle-tech-box div h4 {
    font-size: 22px
  }

  .shuttle-tech-box div {
    top: 100%
  }

  .app-booking-box .app-booking-mainhead {
    font-size: 22px
  }

  .app-booking-content .heading {
    font-size: 18px
  }

  .shuttle-service-box {
    padding: 15px;
    width: 100%
  }

  .shuttle-service-reverse .shuttle-service-box {
    padding: 15px
  }

  .shuttle-service-reverse {
    flex-direction: column
  }

  .start-book-shuttle {
    margin: 60px 0 50px;
    padding: 50px 0
  }

  .appreciation-slider {
    padding: 30px 0
  }

  .appreciate-box-content div {
    width: 100%
  }

  .shuttle-service-box h4 {
    font-size: 22px
  }

  .shuttle-needs {
    margin: 50px 0
  }

  .user-shuttle-need {
    margin-top: 30px
  }

  .shuttle-need-footer h4 br,
  .shuttle-need-footer h2 br {
    display: none
  }

  .shuttle-need-formhead,
  .start-conversation-heading {
    margin-top: 30px
  }

  .shuttle-service-reversed,
  .shuttle-service-row {
    display: flex;
    flex-direction: column-reverse
  }

  .car-rental-subhead {
    padding: 30px 0
  }

  .subhead-main-image {
    margin-top: 20px
  }

  .earning-box h4,
  .earning-box h3 {
    font-size: 24px
  }

  .car-mobility {
    margin: 30px 0
  }

  .car-control {
    margin: 20px 0 50px
  }

  .success-key-area {
    margin: 30px 0
  }

  .success-key-image {
    margin-bottom: 30px
  }

  .page-not-found {
    height: 300px
  }

  .page-not-found p {
    font-size: 18px
  }

  .page-not-found a {
    margin-top: 0
  }

  .page-not-found .col-lg-12 {
    bottom: 25px
  }

  .thankyou-area .thanks-image {
    height: 300px
  }

  .thankyou-content-box p {
    font-size: 16px
  }

  .thankyou-message {
    width: 45px;
    height: 45px;
    margin-bottom: 5px
  }

  .user-info-popup .modal-body {
    padding: 10px
  }

  .user-info-modalbox .heading {
    font-size: 22px
  }

  .modal-userinfo-image {
    border-radius: 8px
  }

  .privacy-content table {
    max-width: 100%;
    display: block;
    overflow-x: scroll
  }

  .privacy-area {
    padding: 50px 0
  }

  .privacy-tab-wrap {
    margin-bottom: 30px
  }

  .taxi-white {
    margin: 50px 0
  }

  .taxi-white .heading {
    font-size: 30px
  }

  .taxi-white p {
    font-size: 20px
  }

  .interface-tabs .tab-content .tab-anim-img {
    left: 45%
  }

  .interface-tabs .col-lg-4 .img-responsive {
    margin-bottom: 30px
  }

  .interface-area {
    margin-bottom: 20px
  }

  .uspbenefit-image {
    position: relative;
    width: 100%;
    top: 15px
  }

  .uspbenefit-reverse {
    display: block
  }

  .uspbenefit-reverse .uspbenefit-box,
  .uspbenefit-box {
    padding: 15px;
    width: 100%
  }

  .uspbenefit-box img {
    width: 40px
  }

  .uspbenefit-box h4 {
    font-size: 24px
  }

  .taxi-operation-area {
    margin: 50px 0
  }

  .operation-tab-row.row {
    margin-top: 30px
  }

  .tab-img-operation {
    margin-top: 30px;
    height: auto
  }

  .taxilaunch-car-1 img {
    width: 80px
  }

  .taxilaunch-car-2 img {
    width: 100px
  }

  .taxitech-area {
    margin: 50px 0
  }

  .taxitech-box div h4,
  .taxitech-box div h3 {
    font-size: 24px
  }

  .taxitech-box div {
    top: 100%
  }

  .taxiondemand-car-1 img {
    width: 100px
  }

  .taxiondemand-car-2 img {
    width: 80px
  }

  .taxi-ondemand .heading br {
    display: none
  }

  .study-head {
    font-size: 24px;
    padding-bottom: 30px
  }

  .details-date-wrap {
    display: block
  }

  .details-date-wrap span {
    margin: 0;
    margin-bottom: 10px
  }

  .case-detail-banner {
    border-radius: 15px;
    margin-bottom: 30px
  }

  .study-detail-titles {
    font-size: 20px
  }

  .quot-details .study-detail-titles {
    font-size: 18px
  }

  .quot-details {
    padding: 10px 0 10px 15px
  }

  .case-detail-content {
    padding-bottom: 16px
  }

  .detail-social-wrap {
    display: block
  }

  .detail-social-wrap .detail-user-box {
    margin-bottom: 15px
  }

  .study-detail-area {
    margin-bottom: 50px
  }

  .head-case-detailcard {
    margin-bottom: 30px
  }

  .detail-card-area {
    margin-bottom: 50px
  }

  .case-detailcard-content .heading {
    font-size: 20px
  }

  .main-head-case {
    font-size: 50px;
    margin-bottom: 30px
  }

  .studybox-detail h4 {
    font-size: 24px
  }

  .studybox-detail ul li {
    padding: 5px 12px
  }

  .case-car-rent {
    margin-top: 0
  }

  .study-wrapper {
    margin-bottom: 30px
  }

  .casestudy-area {
    padding-bottom: 50px
  }

  .circle-waves {
    bottom: -250px;
    width: 480px;
    height: 480px
  }

  .shuttlehead-text .img-responsive {
    width: 330px
  }

  .shuttlehead-text p {
    width: 100%
  }

  .shuttlehead-text label,
  .shuttlehead-text h1 {
    font-size: 24px
  }

  .shuttexplore-vector-1 {
    width: 280px !important;
    top: -170px
  }

  .netzero-vector {
    width: 200px;
    top: -100px
  }

  .netzero-box {
    margin-top: 30px
  }

  .netzero-box h4 {
    font-size: 22px
  }

  .netzero-area {
    padding: 50px 0
  }

  .shuttservice-area {
    padding: 20px 0 50px
  }

  .shuttservice-row {
    margin: 30px 0 0px !important
  }

  .shuttservice-detail h4 {
    font-size: 22px
  }

  .shuttservice-image img {
    width: 100%
  }

  .shuttservice-image {
    margin: 0
  }

  .moretext.show {
    padding: 30px
  }

  .shuttle-solution {
    margin: 50px 0
  }

  .shuttsolution-box {
    margin-top: 30px
  }

  .shuttsolu-bigbox span,
  .shuttsolution-box span {
    width: 40px;
    height: 40px
  }

  .shuttsolu-bigbox span img,
  .shuttsolution-box span img {
    width: 25px
  }

  .shuttsolu-bigbox h4,
  .shuttsolu-bigbox h3,
  .shuttsolution-box h4,
  .shuttsolution-box h3 {
    font-size: 20px
  }

  .booking-shuttle-head {
    margin-bottom: 30px
  }

  .suttbook-fixed {
    position: static
  }

  .suttbook-fixed img {
    width: 100%;
    margin-bottom: 30px
  }

  .suttbook-gap {
    margin: 50px 0;
    overflow: hidden;
    padding-bottom: 10px
  }

  .suttle-booking-reversed {
    flex-direction: column-reverse
  }

  .shutbook-vector-1 {
    width: 150px;
    left: -80px
  }

  .shutbook-vector-2 {
    width: 330px
  }

  .shutbook-vector-3 {
    left: -140px;
    width: 260px
  }

  .shuttle-service-image {
    position: relative;
    transform: translateY(0);
    margin-bottom: 20px;
    width: 100%
  }

  .shuttle-service-reverse,
  .shuttle-service-row {
    margin-top: 50px
  }

  .shuttpage-business {
    margin-bottom: 50px
  }

  .price-head-vec2 {
    width: 60px
  }

  .price-head-vec1 {
    width: 60px;
    bottom: -10px
  }

  .price-head-content label {
    font-size: 20px
  }

  .price-head-content .sub-heading {
    font-size: 16px
  }

  .pricing-launch-area {
    padding: 50px 0 40px
  }

  .pricing-launch-area .heading br {
    display: none
  }

  .pricing-launch-box h4,
  .pricing-launch-box h3 {
    font-size: 22px
  }

  .pricing-launch-box {
    margin: 30px 0 0
  }

  .price-service-vec1 {
    width: 70px;
    top: -40px
  }

  .price-business-box {
    margin-bottom: 30px
  }

  .price-business-box .heading {
    font-size: 22px
  }

  .price-business-content .accordion-header button {
    font-size: 16px
  }

  .price-searvices {
    margin: 20px 0 50px
  }

  .price-searvices .heading br {
    display: none
  }

  .price-manager {
    padding-bottom: 20px
  }

  .price-manager-box {
    margin-bottom: 15px
  }

  .price-manager-box .heading {
    font-size: 22px
  }

  .price-partners {
    padding: 30px 0
  }

  .price-partners h5 {
    font-size: 24px
  }

  .price-partners span {
    font-size: 16px
  }

  .pricing-faq {
    margin-top: 50px
  }

  .price-contact {
    padding: 50px 0;
    margin-bottom: 5px
  }

  .price-contact .heading {
    font-size: 32px
  }

  .price-contact p {
    font-size: 16px
  }

  .price-manager-vec1 {
    width: 70px
  }

  .price-manager-vec2 {
    width: 80px;
    bottom: -20px
  }

  .col-lg-6.pricing-manage-two {
    padding-left: 15px;
    margin-top: 30px
  }

  .price-manager-imgwrap {
    width: 90px;
    margin: 0 auto;
    display: block;
    margin-bottom: 30px
  }

  .col-lg-6.pricing-manage-one {
    padding-right: 15px
  }

  .footer-policy-box a {
    margin-top: 8px
  }

  .footer-social {
    justify-content: right
  }

  .certificate-info-popup {
    padding-left: 0px !important
  }

  .certificate-info-popup .modal-body {
    padding: 20px
  }

  .modal-certificate img {
    width: 80%;
    margin: 0 auto
  }

  .modal-certificate-content .heading {
    font-size: 22px;
    margin: 20px 0 10px;
    text-align: center
  }

  .certificate-vector {
    width: 88%;
    height: auto
  }

  .footer-logo br {
    display: none
  }

  .bike-Rental-head {
    padding: 30px 0
  }

  .bike-rental-head-wrap .default-btn {
    margin-top: 15px
  }

  .bike-rental-workflow {
    margin: 50px 0
  }

  .br-playstore-btn a img {
    width: 100px
  }

  .sub-heading br {
    display: none
  }

  .br-playstore-btn {
    margin-bottom: 30px
  }

  .br-animate-box {
    width: 300px;
    height: 300px
  }

  .br-img-animation img {
    margin: 30px auto;
    width: 220px
  }

  .bike-renatl-mobility .center-heading {
    margin-bottom: 30px
  }

  .brmobility-data {
    padding: 15px;
    margin-right: 0;
    padding-bottom: 0
  }

  .br-mobility-link .nav-link div img {
    width: 25px
  }

  .br-mobility-link .nav-link div h4 {
    font-size: 18px
  }

  .br-mobility-link .nav-link span {
    font-size: 40px
  }

  .br-workflow-box .heading {
    font-size: 20px
  }

  .br-mobility-content h4,
  .br-mobility-content h3 {
    font-size: 22px
  }

  .electric-bike-area {
    margin: 50px 0
  }

  .br-mobility-link .nav-item {
    margin-bottom: 20px
  }

  .cartechtab-link .nav-item:last-child {
    margin-bottom: 20px
  }

  .br-mobility-link {
    margin-top: 0
  }

  .electric-bike-wrapper {
    margin-top: 30px
  }

  .bike-leaders-area .heading br {
    display: none
  }

  .bike-leaders-box {
    flex-direction: column-reverse
  }

  .bike-leaders-text {
    padding: 15px;
    width: 100%
  }

  .bike-leaders-img {
    width: 100%
  }

  .bike-leaders-img img {
    width: 100%
  }

  .bike-leaders-text h5,
  .bike-leaders-text h3 {
    min-height: 50px
  }

  .bike-leaders-slider {
    margin-top: 10px
  }

  .why-wait-area {
    margin: 50px 0 20px
  }

  .why-wait-content .heading {
    font-size: 24px
  }

  .why-wait-content p {
    font-size: 14px
  }

  .why-wait-content p br {
    display: none
  }

  .web-booking-area {
    margin-bottom: 50px
  }

  .web-booking-box {
    padding: 15px;
    margin: 30px 0 0 0
  }

  .electric-bike-img img {
    width: 100%
  }

  .bike-rental-head-wrap .heading {
    font-size: 24px
  }

  .bike-rental-head-wrap .sub-heading {
    font-size: 12px
  }

  .custom-app-banner {
    margin-bottom: 35px
  }

  .custom-banner-img img {
    width: 100%;
    height: 300px;
    object-fit: cover
  }

  .custom-app-head {
    width: 100%
  }

  .custom-app-head .sub-heading br,
  .custom-app-head .heading br {
    display: none
  }

  .custom-head-logobox {
    padding: 15px 30px
  }

  .custom-head-logobox img {
    width: 42px
  }

  .custom-app-banner .container {
    bottom: -35px
  }

  .custom-app-need {
    padding: 50px 0
  }

  .need-standout p br {
    display: none
  }

  .need-standout {
    margin-bottom: 30px
  }

  .product-need-head {
    font-size: 20px;
    margin-bottom: 20px
  }

  .custom-app-needlist li {
    font-size: 16px
  }

  .enterprises-head p {
    margin-bottom: 5px
  }

  .enterprises-head p br {
    display: none
  }

  .enterprises-box .heading {
    font-size: 20px
  }

  .custom-cycle {
    padding: 0 !important
  }

  .custom-cycle-details .heading br {
    display: none
  }

  .custom-cycle-details {
    padding-right: 0;
    margin-top: 50px
  }

  .custom-cycle-card {
    position: static;
    margin-top: 20px;
    height: auto
  }

  .custom-cycle-card h4 {
    font-size: 22px
  }

  .custom-cycle-card h4 br {
    display: none
  }

  .why-custom-app {
    margin: 50px 0
  }

  .why-reasons-wrap {
    padding: 0;
    margin-top: 30px
  }

  .why-reasons {
    flex-direction: column
  }

  .why-reasons span {
    width: 100%;
    margin-bottom: 10px
  }

  .why-reasons p {
    width: 100%
  }

  .why-custom-vec {
    width: 50px
  }

  .custom-tools-head p br {
    display: none
  }

  .custom-tools-box {
    margin-top: 20px
  }

  .custom-tool-vec {
    width: 100px
  }

  .custom-tools-box h4 {
    font-size: 22px
  }

  .custom-tech-giant {
    margin: 40px 0 50px
  }

  .tech-giant-details {
    border: 0
  }

  .tech-giant-years {
    margin-top: 20px
  }

  .tech-giant-years span {
    font-size: 40px
  }

  .goodfirm-widget .logo {
    margin: 0 auto
  }

  .goodfirm-widget .widget-t1 {
    text-align: center !important
  }

  .goodfirm-widget .widget-t1 {
    padding-top: 20px
  }

  .web-app-business {
    padding: 50px 0
  }

  .web-business-vec1 {
    width: 230px
  }

  .web-business-vec2 {
    width: 90px
  }

  .web-app-business .heading {
    margin-bottom: 30px
  }

  .web-business-vec3 {
    bottom: -45px;
    width: 55px
  }

  .web-app-service .center-heading br {
    display: none
  }

  .web-app-story {
    margin: 50px 0
  }

  .webapp-story-image {
    margin-bottom: 20px
  }

  .webapp-story-details .heading br {
    display: none
  }

  .web-app-world {
    display: block
  }

  .webapp-world-map {
    position: unset;
    width: 100%;
    display: block
  }

  .world-counts div span {
    font-size: 35px
  }

  .world-counts div label {
    font-size: 13px
  }

  .web-app-conversation {
    margin: 50px 0;
    padding: 30px 0
  }

  .webapp-story-vec {
    bottom: -40px;
    width: 60px
  }

  .custom-cycle.web-app-cycle {
    margin: 0 0 50px
  }

  .webapp-impression-box {
    width: 100%;
    position: relative;
    padding: 20px;
    top: 0;
    margin-bottom: 30px
  }

  .webapp-impression-box:last-child {
    margin-bottom: 0
  }

  .webapp-impression-details {
    margin-top: 20px
  }

  .webapp-impression-details .heading {
    font-size: 22px
  }

  .webapp-impression-details .heading br {
    display: none
  }

  .webapp-impression-details span br {
    display: none
  }

  .web-app-impression {
    padding-bottom: 0px !important
  }

  .webapp-service-box {
    min-height: auto
  }

  .caselist-top-cards h1 br {
    display: none
  }

  .topcards-wrap {
    margin-top: 30px
  }

  .caselist-bottom-cards {
    margin: 40px 0 50px
  }

  .caselist-top-cards h1 br {
    display: none
  }

  .topcards-wrap {
    margin-top: 30px
  }

  .caselist-bottom-cards {
    margin: 40px 0 50px
  }

  .case-banner-area {
    padding: 30px 0
  }

  .case-banner-area .heading {
    font-size: 20px
  }

  .casetext-banner-location div {
    margin-right: 20px
  }

  .case-index-link li a {
    font-size: 14px
  }

  .case-text-details:first-child {
    margin-top: 20px
  }

  .case-text-details h2,
  .case-text-details h3,
  .case-text-details h4 {
    font-size: 22px;
    margin: 25px 0 20px
  }

  .case-scalability {
    flex-direction: column
  }

  .case-scale-detail {
    width: 100%
  }

  .case-scalability img {
    width: 100%;
    margin-top: 30px
  }

  .case-scale-img img {
    width: 20px;
    margin: 0
  }

  .case-detailcards-area {
    margin: 50px 0
  }

  .industries-banner .custom-app-head .heading span {
    font-size: 24px
  }

  .industries-banner .custom-app-head .heading br {
    display: block
  }

  .industry-searvice-box {
    padding: 15px
  }

  .industry-searvice-box h4 {
    min-height: auto
  }

  .industry-logistic {
    margin: 50px 0
  }

  .industry-logistic .heading br {
    display: none
  }

  .industry-left-box div {
    width: 50%
  }

  .industry-right-box div {
    width: 50%
  }

  .industry-desing .industry-design-vec {
    top: -150px;
    width: 60px
  }

  .industry-desing {
    margin: 50px 0
  }

  .industry-design-tab {
    flex-direction: column;
    align-items: start
  }

  .industry-design-tab li button::before {
    bottom: 0
  }

  .industry-design-tab li button {
    padding: 5px 0
  }

  .design-tab-wrap .industry-truck-img {
    width: 80px
  }

  .industry-highend .heading br {
    display: none
  }

  .industry-highend-details {
    margin-top: 30px
  }

  .track-details-wrap {
    flex-direction: column
  }

  .track-details-box {
    width: 100%
  }

  .industry-highend-vec1 {
    width: 40px;
    transform: translateY(10%)
  }

  .industry-highend-two {
    margin: 40px 0 50px
  }

  .industry-highend-two .industry-highend-img {
    position: relative;
    transform: none;
    width: 100%;
    margin-bottom: 30px
  }

  .industry-transform {
    margin: 50px 0
  }

  .industry-transform .heading br {
    display: none
  }

  .industry-transform-box img {
    width: 120px
  }

  .consultation-content-industry h4 {
    font-size: 20px
  }

  .consultation-content-industry h4 br {
    display: none
  }

  .industry-highend-vec2 {
    width: 50px;
    bottom: -50px
  }

  .industry-need-area .heading br {
    display: none
  }

  .industry-need-tab {
    margin: 30px 0 15px !important;
    flex-direction: column;
    align-items: start
  }

  .industry-need-tab li button {
    padding: 10px 20px !important
  }

  .industry-need-tab li button::before {
    opacity: 0
  }

  .industry-needtab-content {
    flex-direction: column
  }

  .industry-needtab-content img {
    width: 100%
  }

  .industry-needtab-content div {
    position: relative;
    transform: none;
    width: 100%;
    margin-top: 30px;
    left: 0;
    padding: 0 15px
  }

  .industry-pricing {
    padding: 30px 0;
    margin-bottom: 50px
  }

  .industry-pricing span {
    font-size: 16px
  }

  .healthcare-banner label {
    font-size: 12px
  }

  .healthcare-banner .custom-app-head .heading br {
    display: block
  }

  .healthcare-banner .heading span {
    font-size: 22px
  }

  .healthcare-banner .sub-heading {
    font-size: 13px;
    margin: 5px 0 10px
  }

  .health-expertise-img {
    position: static;
    width: 75%;
    margin: 15px auto 0
  }

  .health-expertise-areas .nav-pills .nav-link {
    font-size: 16px
  }

  .health-expertise-areas {
    margin-bottom: 20px
  }

  .health-challenge-box {
    margin: 30px 0 0
  }

  .health-challenge-box .health-challenge-detail {
    padding: 15px
  }

  .health-challenge-box .health-challenge-detail h4 {
    font-size: 20px
  }

  .health-challenge-box .health-challenge-detail p {
    font-size: 12px
  }

  .health-challenge-box .health-challenge-detail h4 br {
    display: none
  }

  .health-expertise-content .heading {
    font-size: 24px
  }

  .health-challenges {
    margin: 50px 0
  }

  .health-challenges .heading br {
    display: none
  }

  .health-partner-detail span {
    font-size: 16px
  }

  .health-partner-detail h3 {
    font-size: 22px
  }

  .patient-support {
    margin: 30px 0 50px
  }

  .patient-support-detail {
    padding: 20px 0
  }

  .patient-support-detail div {
    padding: 15px
  }

  .patient-support-detail div p {
    font-size: 18px
  }

  .patient-support-detail div h3 {
    font-size: 28px
  }

  .patient-support-box h4 {
    min-height: 0
  }

  .health-industry-back img {
    width: 100%;
    height: 270px;
    object-fit: cover
  }

  .health-industry-head {
    top: 10px;
    left: 20px
  }

  .health-industry-box div h4 {
    font-size: 17px
  }

  .health-industry-box div p {
    min-height: auto
  }

  .health-industry-slides button svg {
    font-size: 20px
  }

  .health-industry-box div div {
    padding-bottom: 0;
    transform: translateY(70px)
  }

  .health-tech-area {
    margin: 50px 0
  }

  .health-tech-box img {
    width: 100%
  }

  .health-tech-box div img {
    width: 35px
  }

  .health-tech-box div {
    padding: 15px
  }

  .health-tech-box div h4 {
    font-size: 20px
  }

  .health-tech-head .heading br {
    display: none
  }

  .health-tech-big-box {
    margin-top: 30px
  }

  .health-tech-big-box div span {
    font-size: 38px
  }

  .health-tech-big-box div h4 {
    font-size: 24px
  }

  .health-workflow-content h3 {
    font-size: 24px
  }

  .health-workflow-content h3 br {
    display: none
  }

  .health-workflow-image {
    margin-bottom: 15px
  }

  .health-workflow-2 {
    margin: 60px 0 35px
  }

  .health-workflow-image-2 {
    margin-bottom: 15px
  }

  .health-expertise-content p {
    width: 100%
  }

  .healthcare-banner .custom-app-head h1 br {
    display: block
  }

  .order-app-header .heading br {
    display: none
  }

  .order-app-area {
    overflow: hidden
  }

  .orderapp-img-container .aqua-blush {
    left: -20px
  }

  .orderapp-img-container {
    margin-bottom: 30px
  }

  .orderapp-tabs .nav-pills .nav-link.active,
  .orderapp-tabs .nav-pills .show>.nav-link {
    transform: translateX(0)
  }

  .orderapp-tabs-wrap div h3 {
    font-size: 18px
  }

  .food-vendor {
    margin: 30px 0 50px
  }

  .food-vendor-cards .nav-pills .nav-link.active,
  .food-vendor-cards .nav-pills .show>.nav-link {
    transform: translateX(0)
  }

  .food-vendor-cards .nav-pills .nav-link {
    margin: 0 0 25px 20px
  }

  .food-vendor-card h4 {
    font-size: 18px
  }

  .food-delivery-area {
    overflow: hidden
  }

  .fooddelivery-img-container .orange-blush {
    top: 0
  }

  .fooddelivery-img-container .purple-blush {
    left: 0
  }

  .fooddelivery-img-container {
    margin-bottom: 30px
  }

  .fooddelivery-tabs-wrap div h3 {
    font-size: 18px
  }

  .food-delivery-head h3 {
    font-size: 26px
  }

  .food-admin-area {
    margin: 30px 0 50px
  }

  .food-admin-head div .heading {
    font-size: 32px
  }

  .food-admin-head div {
    position: static;
    transform: translateX(0);
    margin-bottom: 20px;
    margin-top: -90px
  }

  .food-admin-img img {
    border-radius: 12px
  }

  .food-admin-wrapper {
    margin-top: 20px;
    padding: 20px
  }

  .food-admin-box::before {
    display: none
  }

  .food-admin-box {
    padding: 0;
    margin-bottom: 30px
  }

  .foodadmin-last-box {
    margin-bottom: 0
  }

  .food-addon-area {
    margin: 50px 0
  }

  .food-smbs-head .heading br {
    display: none
  }

  .food-smbs-box span {
    font-size: 35px
  }

  .smbs-image-wrapper {
    margin-bottom: 15px
  }

  .food-speed-area {
    margin-top: 35px
  }

  .pharm-banner {
    display: block
  }

  .pharm-banner .container {
    position: relative
  }

  .pharm-banner-image {
    margin-top: 20px
  }

  .pharm-banner-details .purple-gradiant {
    font-size: 12px
  }

  .pharm-banner-details h1 small {
    font-size: 28px;
    width: 90%;
    display: inline-block
  }

  .pharmacy-order-area {
    padding: 50px 0
  }

  .pharm-order-vec {
    width: 200px
  }

  .pharmacy-order-area .heading br {
    display: none
  }

  .branded-pharm-area {
    padding-top: 30px
  }

  .pharmacy-brand-detail .heading {
    line-height: 45px
  }

  .pharm-brand-image .brand-vec-top {
    width: 45px;
    left: -5px
  }

  .pharm-brand-image {
    margin-top: 30px
  }

  .brand-farm-box {
    margin-top: 30px
  }

  .pharmacy-step-detail span {
    font-size: 18px
  }

  .pharmacy-step-detail h4 {
    font-size: 24px
  }

  .pharm-growth {
    margin: 50px 0
  }

  .pharm-growth-head {
    margin-bottom: 0
  }

  .pharm-growth-img {
    margin: 30px 0 20px
  }

  .pharm-growth-details span {
    font-size: 12px
  }

  .pharm-growth-details h4 {
    font-size: 22px
  }

  .pharm-growth-details {
    margin: 0
  }

  .ecom-banner .custom-app-head {
    width: 100%
  }

  .ecom-banner label {
    font-size: 11px
  }

  .ecom-banner.custom-app-banner {
    margin-bottom: 0
  }

  .ecom-banner .sub-heading {
    margin: 10px 0 20px 0
  }

  .ecom-banner .heading {
    font-size: 26px
  }

  .ecom-banner .heading span {
    font-size: 24px
  }

  .ecom-retail-area .heading {
    font-size: 24px
  }

  .ecom-retail-area {
    padding: 50px 0
  }

  .ecom-build-area .heading {
    margin-bottom: 30px
  }

  .vertical-accordion {
    height: 310px
  }

  .vertical-accordion__item:not(.active__item) {
    flex: 0 0 35px
  }

  .vertical-accordion__header {
    font-size: 11px;
    width: 33px
  }

  .active__content {
    padding: 25px 10px
  }

  .vertical-accordion__title {
    font-size: 16px
  }

  .vertical-accordion__text {
    font-size: 10px
  }

  .vertical-accordion__background:after {
    background: rgb(0 0 0 / .6)
  }

  .ecom-collaborate-area {
    margin: 50px 0
  }

  .ecom-collaborate-detail h4 {
    font-size: 22px
  }

  .ecom-system-upper {
    margin-top: 50px
  }

  .ecom-system-lower {
    margin-bottom: 50px
  }

  .ecom-system-upper h3 {
    font-size: 22px
  }

  .ecom-system-upper h3 br {
    display: none
  }

  .ecom-erp-wrap h4 {
    font-size: 20px
  }

  .ecom-erp-vector {
    width: 50px;
    bottom: -50px
  }

  .ecom-room-line img {
    display: none
  }

  .ecom-room-wrapper div:last-child {
    margin-bottom: 30px
  }

  .ecom-rooms .default-btn {
    margin-top: 0
  }

  .ecom-consult-wrap {
    margin-top: 20px
  }

  .ecom-consult-box div h4 {
    font-size: 22px
  }

  .ecom-consult-area {
    margin-bottom: 50px
  }

  .ecom-client {
    margin: 50px 0
  }

  .ecom-case-area {
    margin-top: 50px
  }

  .ecomcase-text-slides .slick-slider {
    height: 220px
  }

  .ecom-textslide-details img {
    width: 65px
  }

  .ecom-textslide-details h4 {
    font-size: 20px
  }

  .ecomcase-text-slides .ecom-textslide-details {
    margin-top: 35px
  }

  .comingsoon-detail {
    width: 90%;
    top: 64%
  }

  .comingsoon-detail h1 {
    font-size: 18px
  }

  .comingsoon-detail .default-white {
    margin-top: 15px
  }

  /* -----education app page---- */

  .bespoke-education{
    padding: 50px 0;
  }
  .bespoke-content .heading label{
    font-size: 28px;
  }
  .educ-service-tabhead h4{
    font-size: 22px;
  }
  .educ-service-tabhead::before{
    display: none;
  }
  .elearning-area{
    margin: 50px 0 20px;
  }
  .elearning-boxes img{
    width: 110px;
  }
  .elearning-boxes:last-child{
    margin-bottom: 30px;
  }
  .great-edu-section{
    margin: 50px 0;
  }
  .great-edu-box span{
    font-size: 20px;
    display: none;
  }
  .edu-counter-content{
    margin-bottom: 20px;
  }
  .edu-industry{
    margin-top: 50px;
  }
  .edu-counter-content div span span{
    font-size: 55px;
  }
}

@media (max-width:599px) {
  .blog-main-area {
    padding-top: 0;
    margin-bottom: 50px;
    padding-right: 0
  }

  .blog-top-content {
    padding: 15px 0
  }

  .blog-main-content h1 {
    font-size: 22px
  }

  .blog-main-wrapper .col-lg-6.left-pad-fix {
    padding-left: 15px
  }

  .blog-main-wrapper .col-lg-6.right-pad-fix {
    padding-right: 15px
  }

  .blog-mainsec-img img {
    border-radius: 15px;
    height: auto;
    margin-bottom: 15px
  }

  .our-blogs-area {
    margin-bottom: 50px
  }

  .blog-card-content h4 {
    font-size: 20px
  }

  .sub-header-content h1 {
    font-size: 26px
  }

  .sub-header-content p {
    font-size: 15px;
    display: block;
    text-align: center
  }

  .sub-header-content p span {
    font-size: 15px;
    vertical-align: middle
  }

  .sub-header {
    padding: 60px 0
  }

  .contact-info-area {
    margin-bottom: 50px
  }

  .contact-box-wrap {
    height: auto;
    padding: 20px 15px
  }

  .contact-box-content h3 {
    font-size: 24px;
    margin: 15px 0 6px
  }

  .contact-image {
    border-radius: 5px 5px 0 0
  }

  .cont-number div {
    padding: 0 10px
  }

  .cont-number div h4,
  .cont-number div h3 {
    font-size: 34px
  }

  .get-touch-img {
    margin: 0 auto;
    margin-bottom: 30px
  }

  .in-touch-form {
    padding: 30px 20px
  }

  .conform-phone div {
    width: 100%;
    margin-right: 0
  }

  .conform-phone {
    display: flex;
    align-items: center;
    flex-wrap: wrap
  }

  .name-email-wrap {
    flex-direction: column
  }

  .name-email-wrap input {
    width: 100%
  }

  .get-intouch-place {
    margin: 50px 0
  }

  .header-detail-place h1 {
    font-size: 25px
  }

  .blog-list-content h2,
  .blog-list-content h3,
  .blog-list-content h4 {
    font-size: 22px
  }

  .blog-detail-area {
    margin-bottom: 50px
  }

  .blogdetail-cards {
    margin-bottom: 20px
  }

  .shuttle-teamlogo {
    margin: 50px 0 30px
  }

  .shuttle-tech-area .heading.center-heading br {
    display: none
  }

  .shuttle-teamlogo-box div img {
    width: 85px
  }

  .shuttle-tech-box div h4 {
    font-size: 22px
  }

  .shuttle-tech-box div {
    top: 100%
  }

  .app-booking-box .app-booking-mainhead {
    font-size: 22px
  }

  .app-booking-content .heading {
    font-size: 18px
  }

  .shuttle-service-box {
    padding: 15px;
    width: 100%
  }

  .shuttle-service-reverse .shuttle-service-box {
    padding: 15px
  }

  .shuttle-service-reverse {
    flex-direction: column
  }

  .start-book-shuttle {
    margin: 0 0 50px;
    padding: 50px 0
  }

  .appreciation-slider {
    padding: 30px 0
  }

  .appreciate-box-content div {
    width: 100%
  }

  .shuttle-service-box h4,
  .shuttle-service-box h3 {
    font-size: 22px
  }

  .shuttle-needs {
    margin: 50px 0
  }

  .shuttle-footer-fix p {
    width: auto
  }

  .user-shuttle-need {
    margin-top: 30px
  }

  .shuttle-need-footer h4 br,
  .shuttle-need-footer h2 br {
    display: none
  }

  .shuttle-need-formhead,
  .start-conversation-heading {
    margin-top: 30px
  }

  .shuttle-service-reversed,
  .shuttle-service-row {
    display: flex;
    flex-direction: column-reverse
  }

  .car-rental-subhead {
    padding: 30px 0
  }

  .subhead-main-image {
    margin-top: 20px
  }

  .earning-box h4,
  .earning-box h3 {
    font-size: 24px
  }

  .car-mobility {
    margin: 30px 0
  }

  .car-control {
    margin: 20px 0 50px
  }

  .success-key-area {
    margin: 30px 0
  }

  .success-key-image {
    margin-bottom: 30px
  }

  .page-not-found {
    height: 300px
  }

  .page-not-found p {
    font-size: 18px
  }

  .page-not-found a {
    margin-top: 0
  }

  .page-not-found .col-lg-12 {
    bottom: 25px
  }

  .thankyou-area .thanks-image {
    height: 300px
  }

  .thankyou-content-box p {
    font-size: 16px
  }

  .thankyou-message {
    width: 45px;
    height: 45px;
    margin-bottom: 5px
  }

  .user-info-popup .modal-body {
    padding: 10px
  }

  .user-info-modalbox .heading {
    font-size: 22px
  }

  .modal-userinfo-image {
    border-radius: 8px
  }

  .menubar-area {
    width: 100%
  }

  .menubar-area.close-menu {
    transform: translateX(610px)
  }

  .privacy-content table {
    max-width: 100%;
    display: block;
    overflow-x: scroll
  }

  .privacy-area {
    padding: 50px 0
  }

  .privacy-tab-wrap {
    margin-bottom: 30px
  }

  .taxi-white {
    margin: 50px 0
  }

  .taxi-white .heading {
    font-size: 30px
  }

  .taxi-white p {
    font-size: 20px
  }

  .interface-tabs .tab-content .tab-anim-img {
    width: 300px;
    left: 51%
  }

  .interface-tabs .col-lg-4 .img-responsive {
    margin-bottom: 30px
  }

  .interface-area {
    margin-bottom: 20px;
    overflow: hidden
  }

  .uspbenefit-image {
    position: relative;
    width: 100%;
    top: 15px
  }

  .taxitab-wave {
    width: 250px;
    height: 250px
  }

  .taxitab-wave:before {
    width: 200px;
    height: 200px
  }

  .taxitab-wave:after {
    width: 250px;
    height: 250px
  }

  .uspbenefit-reverse {
    display: block
  }

  .uspbenefit-reverse .uspbenefit-box,
  .uspbenefit-box {
    padding: 15px;
    width: 100%
  }

  .uspbenefit-box img {
    width: 40px
  }

  .uspbenefit-box h4 {
    font-size: 24px
  }

  .taxi-operation-area {
    margin: 50px 0
  }

  .operation-tab-row.row {
    margin-top: 30px
  }

  .tab-img-operation {
    margin-top: 30px;
    height: auto
  }

  .taxilaunch-car-1 img {
    width: 80px
  }

  .taxilaunch-car-2 img {
    width: 100px
  }

  .taxitech-area {
    margin: 50px 0
  }

  .taxitech-box div h4,
  .taxitech-box div h3 {
    font-size: 24px
  }

  .taxitech-box div {
    top: 100%
  }

  .taxiondemand-car-1 img {
    width: 100px
  }

  .taxiondemand-car-2 img {
    width: 80px
  }

  .taxi-ondemand .heading br {
    display: none
  }

  .study-head {
    font-size: 24px;
    padding-bottom: 30px
  }

  .details-date-wrap {
    display: block
  }

  .details-date-wrap span {
    margin: 0;
    margin-bottom: 10px
  }

  .case-detail-banner {
    border-radius: 15px;
    margin-bottom: 30px
  }

  .study-detail-titles {
    font-size: 20px
  }

  .quot-details .study-detail-titles {
    font-size: 18px
  }

  .quot-details {
    padding: 10px 0 10px 15px
  }

  .case-detail-content {
    padding-bottom: 16px
  }

  .detail-social-wrap {
    display: block
  }

  .detail-social-wrap .detail-user-box {
    margin-bottom: 15px
  }

  .study-detail-area {
    margin-bottom: 50px
  }

  .head-case-detailcard {
    margin-bottom: 30px
  }

  .detail-card-area {
    margin-bottom: 50px
  }

  .case-detailcard-content .heading {
    font-size: 20px
  }

  .main-head-case {
    font-size: 35px;
    margin-bottom: 30px
  }

  .main-head-case br {
    display: none
  }

  .studybox-detail h4 {
    font-size: 24px
  }

  .studybox-detail ul li {
    padding: 5px 12px
  }

  .case-car-rent {
    margin-top: 0
  }

  .study-wrapper {
    margin-bottom: 30px
  }

  .casestudy-area {
    padding-bottom: 50px
  }

  .circle-waves {
    bottom: -170px;
    width: 340px;
    height: 340px
  }

  .shuttlehead-text .img-responsive {
    width: 270px
  }

  .shuttlehead-text p {
    width: 100%
  }

  .shuttlehead-text label,
  .shuttlehead-text h1 {
    font-size: 24px
  }

  .shuttexplore-vector-1 {
    width: 280px !important;
    top: -170px
  }

  .netzero-vector {
    width: 200px;
    top: -100px
  }

  .netzero-box {
    margin-top: 30px
  }

  .netzero-box h4 {
    font-size: 22px
  }

  .netzero-area {
    padding: 50px 0
  }

  .shuttservice-area {
    padding: 20px 0 50px
  }

  .shuttservice-row {
    margin: 30px 0 0px !important
  }

  .shuttservice-detail h4,
  .shuttservice-detail h2 {
    font-size: 22px
  }

  .shuttservice-image img {
    width: 100%
  }

  .shuttservice-image {
    margin: 0
  }

  .moretext.show {
    padding: 30px
  }

  .shuttle-solution {
    margin: 50px 0
  }

  .shuttsolution-box {
    margin-top: 30px
  }

  .shuttsolu-bigbox span,
  .shuttsolution-box span {
    width: 40px;
    height: 40px
  }

  .shuttsolu-bigbox span img,
  .shuttsolution-box span img {
    width: 25px
  }

  .shuttsolu-bigbox h4,
  .shuttsolu-bigbox h3,
  .shuttsolution-box h4 {
    font-size: 20px
  }

  .booking-shuttle-head {
    margin-bottom: 30px
  }

  .suttbook-fixed {
    position: static
  }

  .suttbook-fixed img {
    width: 100%;
    margin-bottom: 30px
  }

  .suttbook-gap {
    margin: 50px 0;
    overflow: hidden;
    padding-bottom: 10px
  }

  .suttle-booking-reversed {
    flex-direction: column-reverse
  }

  .shutbook-vector-1 {
    width: 150px;
    left: -80px
  }

  .shutbook-vector-2 {
    width: 330px
  }

  .shutbook-vector-3 {
    left: -140px;
    width: 260px
  }

  .shuttle-service-image {
    position: relative;
    transform: translateY(0);
    margin-bottom: 20px;
    width: 100%
  }

  .shuttle-service-reverse,
  .shuttle-service-row {
    margin-top: 50px
  }

  .shuttpage-business {
    margin-bottom: 50px
  }

  .price-head-vec2 {
    width: 60px
  }

  .price-head-vec1 {
    width: 60px;
    bottom: -10px
  }

  .price-head-content label {
    font-size: 20px
  }

  .price-head-content .sub-heading {
    font-size: 16px
  }

  .pricing-launch-area {
    padding: 50px 0 40px
  }

  .pricing-launch-area .heading br {
    display: none
  }

  .pricing-launch-box h4,
  .pricing-launch-box h3 {
    font-size: 22px
  }

  .pricing-launch-box {
    margin: 30px 0 0
  }

  .price-service-vec1 {
    width: 70px;
    top: -40px
  }

  .price-business-box {
    margin-bottom: 30px
  }

  .price-business-box .heading {
    font-size: 22px
  }

  .price-business-content .accordion-header button {
    font-size: 16px
  }

  .price-searvices {
    margin: 20px 0 50px
  }

  .price-searvices .heading br {
    display: none
  }

  .price-manager {
    padding-bottom: 20px
  }

  .price-manager-box {
    margin-bottom: 15px
  }

  .price-manager-box .heading {
    font-size: 22px
  }

  .price-partners {
    padding: 30px 0
  }

  .price-partners h5 {
    font-size: 24px
  }

  .price-partners span {
    font-size: 16px
  }

  .pricing-faq {
    margin-top: 50px
  }

  .price-contact {
    padding: 50px 0;
    margin-bottom: 5px
  }

  .price-contact .heading {
    font-size: 32px
  }

  .price-contact p {
    font-size: 16px
  }

  .price-manager-vec1 {
    width: 70px
  }

  .price-manager-vec2 {
    width: 80px;
    bottom: -20px
  }

  .col-lg-6.pricing-manage-two {
    padding-left: 15px;
    margin-top: 30px
  }

  .price-manager-imgwrap {
    width: 70px;
    margin: 0 auto;
    display: block;
    margin-bottom: 30px
  }

  .col-lg-6.pricing-manage-one {
    padding-right: 15px
  }

  .price-searvice-box {
    margin: 50px 0 0
  }

  .footer-policy-box a {
    font-size: 11px;
    margin-top: 8px
  }

  .footer-social {
    justify-content: right
  }

  .certificate-info-popup {
    padding-left: 0px !important
  }

  .certificate-info-popup .modal-body {
    padding: 20px
  }

  .modal-certificate img {
    width: 80%;
    margin: 0 auto
  }

  .modal-certificate-content .heading {
    font-size: 20px;
    margin: 20px 0 10px;
    text-align: center
  }

  .certificate-vector {
    width: 88%;
    height: auto
  }

  .footer-logo br {
    display: none
  }

  .bike-Rental-head {
    padding: 30px 0
  }

  .bike-rental-head-wrap .default-btn {
    margin-top: 15px
  }

  .bike-rental-workflow {
    margin: 50px 0
  }

  .br-playstore-btn a img {
    width: 100px
  }

  .sub-heading br {
    display: none
  }

  .br-playstore-btn {
    margin-bottom: 30px
  }

  .br-animate-box {
    width: 230px;
    height: 230px
  }

  .br-img-animation img {
    margin: 30px auto;
    width: 180px
  }

  .bike-renatl-mobility .center-heading {
    margin-bottom: 30px
  }

  .brmobility-data {
    padding: 15px;
    margin-right: 0;
    padding-bottom: 0
  }

  .br-mobility-link .nav-link div img {
    width: 25px
  }

  .br-mobility-link .nav-link div h4 {
    font-size: 18px
  }

  .br-mobility-link .nav-link span {
    font-size: 40px
  }

  .br-workflow-box .heading {
    font-size: 20px
  }

  .br-mobility-content h4,
  .br-mobility-content h3 {
    font-size: 22px
  }

  .electric-bike-area {
    margin: 50px 0
  }

  .br-mobility-link .nav-item {
    margin-bottom: 20px
  }

  .cartechtab-link .nav-item:last-child {
    margin-bottom: 20px
  }

  .br-mobility-link {
    margin-top: 0
  }

  .electric-bike-wrapper {
    margin-top: 30px
  }

  .bike-leaders-area .heading br {
    display: none
  }

  .bike-leaders-box {
    flex-direction: column-reverse
  }

  .bike-leaders-text {
    padding: 15px;
    width: 100%
  }

  .bike-leaders-img {
    width: 100%
  }

  .bike-leaders-img img {
    width: 100%
  }

  .bike-leaders-text h5,
  .bike-leaders-text h3 {
    min-height: 50px
  }

  .bike-leaders-slider {
    margin-top: 10px
  }

  .why-wait-area {
    margin: 50px 0 20px
  }

  .why-wait-content .heading {
    font-size: 24px
  }

  .why-wait-content p {
    font-size: 14px
  }

  .why-wait-content p br {
    display: none
  }

  .web-booking-area {
    margin-bottom: 50px
  }

  .web-booking-box {
    padding: 15px;
    margin: 30px 0 0 0
  }

  .bike-rental-head-wrap .heading {
    font-size: 20px
  }

  .bike-rental-head-wrap .sub-heading {
    font-size: 12px
  }

  .custom-app-banner {
    margin-bottom: 30px
  }

  .custom-banner-img img {
    width: 100%;
    height: 300px;
    object-fit: cover
  }

  .custom-app-head {
    width: 100%
  }

  .custom-app-head .sub-heading br,
  .custom-app-head .heading br {
    display: none
  }

  .custom-head-logobox {
    padding: 15px 30px
  }

  .custom-head-logobox img {
    width: 32px
  }

  .custom-app-banner .container {
    bottom: -30px
  }

  .custom-app-need {
    padding: 50px 0
  }

  .need-standout p br {
    display: none
  }

  .need-standout {
    margin-bottom: 30px
  }

  .product-need-head {
    font-size: 20px;
    margin-bottom: 20px
  }

  .custom-app-needlist li {
    font-size: 16px
  }

  .enterprises-head p {
    margin-bottom: 5px
  }

  .enterprises-head p br {
    display: none
  }

  .enterprises-box .heading {
    font-size: 20px
  }

  .custom-cycle {
    padding: 0 !important
  }

  .custom-cycle-details .heading br {
    display: none
  }

  .custom-cycle-details {
    padding-right: 0;
    margin-top: 50px
  }

  .custom-cycle-card {
    position: static;
    margin-top: 20px;
    height: auto
  }

  .custom-cycle-card h4 {
    font-size: 22px
  }

  .custom-cycle-card h4 br {
    display: none
  }

  .why-custom-app {
    margin: 50px 0
  }

  .why-reasons-wrap {
    padding: 0;
    margin-top: 30px
  }

  .why-reasons {
    flex-direction: column
  }

  .why-reasons span {
    width: 100%;
    margin-bottom: 10px
  }

  .why-reasons p {
    width: 100%
  }

  .why-custom-vec {
    width: 50px
  }

  .custom-tools-head p br {
    display: none
  }

  .custom-tools-box {
    margin-top: 20px
  }

  .custom-tool-vec {
    width: 100px
  }

  .custom-tools-box h4 {
    font-size: 18px
  }

  .custom-tech-giant {
    margin: 40px 0 50px
  }

  .tech-giant-details {
    border: 0
  }

  .tech-giant-years {
    margin-top: 20px
  }

  .tech-giant-years span {
    font-size: 40px
  }

  .goodfirm-widget {
    padding: 15px 0;
    height: 190px
  }

  .goodfirm-widget .logo {
    margin: 0 auto
  }

  .goodfirm-widget .widget-t1 {
    text-align: center !important
  }

  .goodfirm-widget .widget-t1 {
    padding-top: 20px
  }

  .web-app-business {
    padding: 50px 0
  }

  .web-business-vec1 {
    width: 230px
  }

  .web-business-vec2 {
    width: 90px
  }

  .web-app-business .heading {
    margin-bottom: 30px
  }

  .web-business-vec3 {
    bottom: -45px;
    width: 55px
  }

  .web-app-service .center-heading br {
    display: none
  }

  .web-app-story {
    margin: 50px 0
  }

  .webapp-story-image {
    margin-bottom: 20px
  }

  .webapp-story-details .heading br {
    display: none
  }

  .web-app-world {
    display: block
  }

  .webapp-world-map {
    position: unset;
    width: 100%;
    display: block
  }

  .world-counts div span {
    font-size: 35px
  }

  .world-counts div label {
    font-size: 13px
  }

  .web-app-conversation {
    margin: 50px 0;
    padding: 30px 0
  }

  .webapp-story-vec {
    bottom: -40px;
    width: 60px
  }

  .custom-cycle.web-app-cycle {
    margin: 0 0 50px
  }

  .webapp-impression-box {
    width: 100%;
    position: relative;
    padding: 20px;
    top: 0;
    margin-bottom: 30px
  }

  .webapp-impression-box:last-child {
    margin-bottom: 0
  }

  .webapp-impression-details {
    margin-top: 20px
  }

  .webapp-impression-details .heading {
    font-size: 22px
  }

  .webapp-impression-details .heading br {
    display: none
  }

  .webapp-impression-details span br {
    display: none
  }

  .web-app-impression {
    padding-bottom: 0px !important
  }

  .webapp-service-box {
    min-height: auto
  }

  .caselist-top-cards h1 br {
    display: none
  }

  .topcards-wrap {
    margin-top: 30px
  }

  .caselist-bottom-cards {
    margin: 40px 0 50px
  }

  .case-banner-area {
    padding: 30px 0
  }

  .case-banner-area .heading {
    font-size: 20px
  }

  .casetext-banner-location div {
    margin-right: 20px
  }

  .case-index-link li a {
    font-size: 14px
  }

  .case-text-details:first-child {
    margin-top: 20px
  }

  .case-text-details h2,
  .case-text-details h3,
  .case-text-details h4 {
    font-size: 22px;
    margin: 25px 0 20px
  }

  .case-scalability {
    flex-direction: column
  }

  .case-scale-detail {
    width: 100%
  }

  .case-scalability img {
    width: 100%;
    margin-top: 30px
  }

  .case-scale-img img {
    width: 20px;
    margin: 0
  }

  .case-detailcards-area {
    margin: 50px 0
  }

  .industries-banner .custom-app-head .heading span {
    font-size: 22px
  }

  .industries-banner .custom-app-head .heading br {
    display: block
  }

  .industry-searvice-box {
    padding: 15px
  }

  .industry-searvice-box h4 {
    min-height: auto
  }

  .industry-logistic {
    margin: 50px 0
  }

  .industry-logistic .heading br {
    display: none
  }

  .industry-left-box div {
    width: 80%
  }

  .industry-right-box div {
    width: 80%
  }

  .industry-desing .industry-design-vec {
    top: -150px;
    width: 60px
  }

  .industry-desing {
    margin: 50px 0
  }

  .industry-design-tab {
    flex-direction: column;
    align-items: start
  }

  .industry-design-tab li button::before {
    bottom: 0
  }

  .industry-design-tab li button {
    padding: 5px 0
  }

  .design-tab-wrap .industry-truck-img {
    width: 80px
  }

  .industry-highend .heading br {
    display: none
  }

  .industry-highend-details {
    margin-top: 30px
  }

  .track-details-wrap {
    flex-direction: column
  }

  .track-details-box {
    width: 100%
  }

  .industry-highend-vec1 {
    width: 40px;
    transform: translateY(10%)
  }

  .industry-highend-two {
    margin: 40px 0 50px
  }

  .industry-highend-two .industry-highend-img {
    position: relative;
    transform: none;
    width: 100%;
    margin-bottom: 30px
  }

  .industry-transform {
    margin: 50px 0
  }

  .industry-transform .heading br {
    display: none
  }

  .industry-transform-box img {
    width: 120px
  }

  .consultation-content-industry h4 {
    font-size: 20px
  }

  .consultation-content-industry h4 br {
    display: none
  }

  .industry-highend-vec2 {
    width: 50px;
    bottom: -50px
  }

  .industry-need-area .heading br {
    display: none
  }

  .industry-need-tab {
    margin: 30px 0 15px !important;
    flex-direction: column;
    align-items: start
  }

  .industry-need-tab li button {
    padding: 10px 20px !important
  }

  .industry-need-tab li button::before {
    opacity: 0
  }

  .industry-needtab-content {
    flex-direction: column
  }

  .industry-needtab-content img {
    width: 100%
  }

  .industry-needtab-content div {
    position: relative;
    transform: none;
    width: 100%;
    margin-top: 30px;
    left: 0;
    padding: 0 15px
  }

  .industry-pricing {
    padding: 30px 0;
    margin-bottom: 50px
  }

  .industry-pricing span {
    font-size: 14px
  }

  .healthcare-banner label {
    font-size: 11px
  }

  .healthcare-banner .heading span {
    font-size: 22px
  }

  .healthcare-banner .sub-heading {
    font-size: 13px;
    margin: 5px 0 10px
  }

  .health-expertise-img {
    position: static;
    margin-top: 15px
  }

  .health-expertise-areas .nav-pills .nav-link {
    font-size: 16px
  }

  .health-expertise-areas {
    margin-bottom: 20px
  }

  .health-expertise-content .heading {
    font-size: 24px
  }

  .health-challenge-box {
    margin: 30px 10px 0
  }

  .health-challenge-box .health-challenge-detail p {
    min-height: 0
  }

  .health-challenges {
    margin: 50px 0
  }

  .health-challenges .heading br {
    display: none
  }

  .health-partner-detail span {
    font-size: 16px
  }

  .health-partner-detail h3 {
    font-size: 22px
  }

  .health-partner-detail p br {
    display: none
  }

  .patient-support {
    margin: 30px 0 50px
  }

  .patient-support-detail {
    padding: 20px 0
  }

  .patient-support-detail div {
    padding: 15px
  }

  .patient-support-detail div p {
    font-size: 18px
  }

  .patient-support-detail div h3 {
    font-size: 28px
  }

  .patient-support-box h4 {
    min-height: 0
  }

  .health-industry-back img {
    width: 100%;
    height: 220px;
    object-fit: cover
  }

  .health-industry-head {
    top: 10px;
    left: 20px
  }

  .health-industry-box div h4 {
    font-size: 17px
  }

  .health-industry-box div p {
    min-height: auto
  }

  .health-industry-slides button svg {
    font-size: 20px
  }

  .health-industry-box div div {
    padding-bottom: 0
  }

  .health-tech-area {
    margin: 50px 0
  }

  .health-tech-head .heading br {
    display: none
  }

  .health-tech-big-box {
    margin-top: 30px
  }

  .health-tech-big-box div span {
    font-size: 38px
  }

  .health-tech-big-box div h4 {
    font-size: 24px
  }

  .health-workflow-content h3 {
    font-size: 24px
  }

  .health-workflow-content h3 br {
    display: none
  }

  .health-workflow-image {
    margin-bottom: 15px
  }

  .health-workflow-2 {
    margin: 60px 0 35px
  }

  .health-workflow-image-2 {
    margin-bottom: 15px
  }

  .health-workflow-content-2 .workflow-list {
    flex-direction: column;
    align-items: start
  }

  .health-expertise-content p {
    width: 100%
  }

  .healthcare-banner .custom-app-head h1 br {
    display: block
  }

  .order-app-header .heading br {
    display: none
  }

  .order-app-area {
    overflow: hidden
  }

  .orderapp-img-container .aqua-blush {
    left: -20px
  }

  .orderapp-img-container {
    margin-bottom: 30px
  }

  .orderapp-tabs .nav-pills .nav-link.active,
  .orderapp-tabs .nav-pills .show>.nav-link {
    transform: translateX(0)
  }

  .orderapp-tabs-wrap div h3 {
    font-size: 18px
  }

  .food-vendor {
    margin: 30px 0 50px
  }

  .food-vendor-cards .nav-pills .nav-link.active,
  .food-vendor-cards .nav-pills .show>.nav-link {
    transform: translateX(0)
  }

  .food-vendor-cards .nav-pills .nav-link {
    margin: 0 0 25px 20px
  }

  .food-vendor-card h4 {
    font-size: 18px
  }

  .food-delivery-area {
    overflow: hidden
  }

  .fooddelivery-img-container .orange-blush {
    top: 0
  }

  .fooddelivery-img-container .purple-blush {
    left: 0
  }

  .fooddelivery-img-container {
    margin-bottom: 30px
  }

  .fooddelivery-tabs-wrap div h3 {
    font-size: 18px
  }

  .food-delivery-head h3 {
    font-size: 26px
  }

  .food-admin-area {
    margin: 30px 0 50px
  }

  .food-admin-head div .heading {
    font-size: 22px
  }

  .food-admin-head div {
    position: static;
    transform: translateX(0);
    margin-bottom: 20px;
    margin-top: -60px
  }

  .food-admin-img img {
    border-radius: 12px
  }

  .food-admin-wrapper {
    margin-top: 20px;
    padding: 20px
  }

  .food-admin-box::before {
    display: none
  }

  .food-admin-box {
    padding: 0;
    margin-bottom: 30px
  }

  .foodadmin-last-box {
    margin-bottom: 0
  }

  .food-addon-area {
    margin: 50px 0
  }

  .food-smbs-head .heading br {
    display: none
  }

  .food-smbs-box span {
    font-size: 35px
  }

  .smbs-image-wrapper {
    margin-bottom: 15px
  }

  .food-speed-area {
    margin-top: 35px
  }

  .pharm-banner {
    display: block
  }

  .pharm-banner .container {
    position: relative
  }

  .pharm-banner-image {
    margin-top: 20px
  }

  .pharm-banner-details .purple-gradiant {
    font-size: 12px
  }

  .pharm-banner-details h1 small {
    font-size: 25px;
    width: 90%;
    display: inline-block
  }

  .pharmacy-order-area {
    padding: 50px 0
  }

  .pharm-order-vec {
    width: 200px
  }

  .pharmacy-order-area .heading br {
    display: none
  }

  .branded-pharm-area {
    padding-top: 30px
  }

  .pharmacy-brand-detail .heading {
    line-height: 45px
  }

  .pharm-brand-image .brand-vec-top {
    width: 45px;
    left: -5px
  }

  .pharm-brand-image {
    margin-top: 30px
  }

  .brand-farm-box {
    margin-top: 30px
  }

  .pharmacy-step-detail span {
    font-size: 18px
  }

  .pharmacy-step-detail h4 {
    font-size: 24px
  }

  .pharm-growth {
    margin: 50px 0
  }

  .pharm-growth-head {
    margin-bottom: 0
  }

  .pharm-growth-img {
    margin: 30px 0 20px
  }

  .pharm-growth-details span {
    font-size: 12px
  }

  .pharm-growth-details h4 {
    font-size: 22px
  }

  .pharm-growth-details {
    margin: 0
  }

  .ecom-banner .custom-app-head {
    width: 100%
  }

  .ecom-banner label {
    font-size: 11px
  }

  .ecom-banner.custom-app-banner {
    margin-bottom: 0
  }

  .ecom-banner .sub-heading {
    margin: 10px 0 20px 0
  }

  .ecom-banner .heading {
    font-size: 26px
  }

  .ecom-banner .heading span {
    font-size: 24px
  }

  .ecom-retail-area .heading {
    font-size: 24px
  }

  .ecom-retail-area {
    padding: 50px 0
  }

  .ecom-build-area .heading {
    margin-bottom: 30px
  }

  .vertical-accordion {
    height: 330px
  }

  .vertical-accordion__item:not(.active__item) {
    flex: 0 0 25px
  }

  .vertical-accordion__header {
    font-size: 11px;
    width: 25px
  }

  .active__content {
    padding: 25px 10px
  }

  .vertical-accordion__title {
    font-size: 16px
  }

  .vertical-accordion__text {
    font-size: 10px
  }

  .vertical-accordion__background:after {
    background: rgb(0 0 0 / .6)
  }

  .ecom-collaborate-area {
    margin: 50px 0
  }

  .ecom-collaborate-detail h4 {
    font-size: 22px
  }

  .ecom-system-upper {
    margin-top: 50px
  }

  .ecom-system-lower {
    margin-bottom: 50px
  }

  .ecom-system-upper h3 {
    font-size: 22px
  }

  .ecom-system-upper h3 br {
    display: none
  }

  .ecom-erp-wrap h4 {
    font-size: 20px
  }

  .ecom-erp-vector {
    width: 50px;
    bottom: -50px
  }

  .ecom-room-line img {
    display: none
  }

  .ecom-room-wrapper div:last-child {
    margin-bottom: 30px
  }

  .ecom-rooms .default-btn {
    margin-top: 0
  }

  .ecom-consult-wrap {
    margin-top: 20px
  }

  .ecom-consult-box div h4 {
    font-size: 22px
  }

  .ecom-consult-area {
    margin-bottom: 50px
  }

  .ecom-client {
    margin: 50px 0
  }

  .ecom-case-area {
    margin-top: 50px
  }

  .ecomcase-text-slides .slick-slider {
    height: 250px
  }

  .ecom-textslide-details img {
    width: 65px
  }

  .ecom-textslide-details h4 {
    font-size: 20px
  }

  .ecomcase-text-slides .ecom-textslide-details {
    margin-top: 40px
  }

  .comingsoon-detail {
    width: 98%;
    top: 63%
  }

  .comingsoon-detail h1 {
    font-size: 16px
  }

  .coming-soon-area img {
    height: 220px;
    object-fit: cover
  }

  .comingsoon-detail .default-white {
    margin-top: 10px
  }

  /* ----education app page----- */

  .bespoke-education{
    padding: 50px 0;
  }
  .bespoke-content .heading label{
    font-size: 24px;
  }
  .educ-service-tabhead h4{
    font-size: 20px;
  }
  .educ-service-tabhead::before{
    display: none;
  }
  .elearning-area{
    margin: 50px 0 20px;
  }
  .elearning-boxes img{
    width: 90px;
  }
  .elearning-boxes div span{
    font-size: 15px;
  }
  .elearning-boxes:last-child{
    margin-bottom: 30px;
  }
  .great-edu-section{
    margin: 50px 0;
  }
  .great-edu-box div h4{
    font-size: 18px;
  }
  .great-edu-box span{
    font-size: 20px;
    display: none;
  }
  .edu-counter-content{
    margin-bottom: 20px;
  }
  .edu-industry{
    margin-top: 50px;
  }
  .edu-counter-content div span span{
    font-size: 45px;
  }
}